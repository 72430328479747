import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { Customer, customerService } from '../services/requests/CustomerService';

interface CustomerContextValue {
  customer: Customer | null;
  loading: boolean;
  error: string;
  moneySizes: number[];
  isOneTimePaymentEnabled: boolean;
  isMoneySizesEnabled: boolean;
}

type Props = {
  children: ReactNode;
};

const CustomerContext = createContext<CustomerContextValue | undefined>(undefined);

const extractCustomerIdFromSubdomain = (): string => {
  const { hostname } = window.location;
  return hostname.split('.')[0];
};

const CustomerProvider = ({ children }: Props) => {
  const [customer, setCustomer] = useState<Customer | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const customerId = extractCustomerIdFromSubdomain();
  const [hasFetched, setHasFetched] = useState(false);
  const [moneySizes, setMoneySizes] = useState<number[]>([]);
  const [isMoneySizesEnabled, setIsMoneySizesEnabled] = useState(false);
  const [isOneTimePaymentEnabled, setIOneTimePaymentEnabled] = useState(false);

  const fetchCustomer = useCallback(async () => {
    if (hasFetched) {
      return;
    }
    setLoading(true);
    setError('');
    try {
      const customerResponse = await customerService.getCustomer(customerId);

      if (!customerResponse) {
        setError('Customer not found');
        return;
      }
      setCustomer(customerResponse);
      setHasFetched(true);

      setMoneySizes(customerResponse.settings.defaultMoneySizes.split(',').map((value: string) => +value));

      const isOneTimePayment = !customerResponse?.settings?.isSubscriptionEnabled;
      const isMoneySizesEnabled = customerResponse?.settings?.isMoneySizesEnabled;

      setIOneTimePaymentEnabled(isOneTimePayment);
      setIsMoneySizesEnabled(isMoneySizesEnabled);


    } catch (error) {
      setError((error as Error).message || 'Failed to fetch customer');
    } finally {
      setLoading(false);
    }
  }, [customerId, hasFetched]);

  useEffect(() => {
    fetchCustomer();
  }, [fetchCustomer]);

  return (
    <CustomerContext.Provider value={{ customer, loading, error, moneySizes, isMoneySizesEnabled, isOneTimePaymentEnabled }}>
      {children}
    </CustomerContext.Provider>
  );
};

const useCustomer = (): CustomerContextValue => {
  const context = useContext(CustomerContext);
  if (context === undefined) {
    throw new Error('useCustomer must be used within a CustomerProvider');
  }
  return context;
};

export { CustomerProvider, useCustomer };
