import { BaseService } from './BaseService';

export interface AuthorizeData {
  domain: string;
  firstName: string;
  lastName: string;
  notes: string;
  amount: number;
  email: string;
  transactionTypeId: number | null;
  subscriptionTypeId?: number | null;
  hideDisplayName: boolean;
  projectId?: number;
  nextUrl: string;
}

class PaymentService extends BaseService {
  async authorizeTransaction(data: AuthorizeData): Promise<{ formUrl: string } | null> {
    const response = await this.post<{
      formUrl: string;
      mdOrderId: string
    }>(`/payments/authorize`, data);

    return response ?? null;
  }

  async checkUnsubscribeToken(unsubscribeToken: string): Promise<{ subscriptionId: number } | null> {
    return this.get<{ subscriptionId: number }>(`/payments/unsubscribe`, { unsubscribe_token: unsubscribeToken });
  }

  async unsubscribe(unsubscribeToken: string): Promise<{ unsubscribed: boolean } | null> {
    return this.post<{ unsubscribed: boolean }>(`/payments/unsubscribe`, { unsubscribeToken });
  }
}

export default new PaymentService();
