import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
import { COMPANY_NAME } from '../helpers/constants.helper';

const resources = {
  ru: {
    translation: {
      amount: 'Сумма',
      transaction: {
        succeed: 'Транзакция успешно выполнена.',
        failed: 'Запрос отклонён.',
      },
      firstName: 'Имя',
      lastName: 'Фамилия',
      email: 'Почта',
      notes: 'Заметки',
      other: 'Другое',
      submit: 'Подтвердить',
      close: 'Закрыть',
      loading: 'Загрузка ․․․',
      notFound: 'По следующей ссылке ничего не найдено.',
      unsubscribeSucceed: "Вы успешно отписались.",
      unsubscribeFailed: "Что-то пошло не так, попробуйте позже.",
      unsubscribeConfirm: "Пожалуйста, подтвердите отписку.",
      unsubscribe: "Отписаться",
      invalidRequest: 'Неправильный запрос.',
      read: `Читать`,
      agreeTermsAndConditions: `Согласен с правилами и условиями.`,
      allRightsReserved: `Все права защищены`,
      powered: `Сделано с любовью компанией ${COMPANY_NAME}.`,
      chooseInitiative: 'Выберите инициативу',
      select: 'Выбирать',
      about: 'О нас',
      projects: 'Проекты',
      donate: 'Пожертвовать',
      home: 'Главная страница',
      contributions: 'пожертвование',
      goal: 'Цель',
      highlights: 'Основные',
      description: 'Описание',
      updates: 'Обновления',
      reports: 'Отчеты',
      donateNow: 'Пожертвовать сейчас',
      seeTop: 'Топ',
      seeAll: 'Все',
      top: 'топ',
      recent: 'недавний',
      first: 'первый',
      processPaymentNow: 'Обработать платеж сейчас',
      raised: 'собранный',
      noContentAvailable: 'Нет контента',
      topProjects: 'Топ проекты',
      emptyProjects: 'Нет доступных проектов',
      emptyContent: 'Нет контента',
      loadingContent: 'Загрузка контента ...',
      contacts: 'Контакты',
      support: 'Поддержать',
      'terms-and-conditions': 'Правила и условия',
      news: 'Новости',
      'Failed to fetch project summary. Missing data.': 'Не удалось получить сводку проекта. Недостающие данные.',
      'Failed to fetch project summary. Please try again later.': 'Не удалось получить сводку проекта. Пожалуйста, попробуйте позже.',
      'That is all the contributors': 'Это все участники',
      'Still waiting for contributors': 'Все еще ждем участников',
      'This field is required': 'Это поле обязательно',
      'Please enter a valid email address': 'Пожалуйста, введите действительный адрес электронной почты',
      'You must accept the terms and conditions': 'Вы должны принять правила и условия',
      'The value must meet the minimum requirement': 'Значение должно соответствовать минимальным требованиям',
      'The value must be at least {{value}}': 'Значение должно быть не менее {{value}}',
      'hideDisplayName': 'Не отображать мое имя публично',
      'Payment could not be processed. Please try again later': 'Платеж не может быть обработан. Пожалуйста, попробуйте позже.',
      'Payment Successful': 'Платеж успешно выполнен.',
      'Payment Failed': 'Платеж не удался.',
      'Payment Successful Text': 'Ваш платеж был успешно обработан! \n Пожалуйста, проверьте адрес электронной почты, который вы указали, для получения квитанции.',
      'Payment Failed Text': 'Ваш платеж был отклонен. Пожалуйста, попробуйте позже.',
      'Provided subscription token is not valid': 'Предоставленный токен подписки недействителен.',
      'whereTheMostNeeded': 'Там, где это наиболее необходимо',
      'ONE-TIME': 'Один раз',
      'SUBSCRIPTION': 'Подписка',
      'DAILY': 'Ежедневно',
      'WEEKLY': 'Еженедельно',
      'MONTHLY': 'Ежемесячно',
    },
  },
  fr: {
    translation: {
      amount: 'Le montant',
      transaction: {
        succeed: 'Le transfert a été effectué avec succès.',
        failed: `L'accord a été rejeté.`,
      },
      firstName: 'Prénom',
      lastName: 'Nom',
      email: 'E-mail Adresse:',
      notes: 'Notes',
      other: 'Autre',
      submit: 'Confirmer',
      close: 'Fermer',
      loading: 'Chargement ...',
      notFound: `Rien n'a été trouvé avec le lien suivant.`,
      unsubscribeSucceed: "Vous avez été désabonné avec succès.",
      unsubscribeFailed: "Quelque chose s'est mal passé, veuillez réessayer plus tard.",
      unsubscribeConfirm: "Veuillez confirmer votre désinscription.",
      unsubscribe: "Se désabonner",
      invalidRequest: 'Requête invalide.',
      read: `Lire`,
      agreeTermsAndConditions: `J'accepte les règles et conditions.`,
      allRightsReserved: `Tous les droits sont réservés.`,
      powered: `Créé avec amour par ${COMPANY_NAME}.`,
      chooseInitiative: 'Choisissez l\'initiative',
      select: 'Sélectionner',
      about: 'À propos de nous',
      projects: 'Projets',
      donate: 'Faire un don',
      home: 'Accueil',
      contributions: 'la donation',
      goal: 'objectif',
      highlights: 'faits saillants',
      description: 'La description',
      updates: 'Mises à jour',
      reports: 'Rapports',
      donateNow: 'Faire un don maintenant',
      seeTop: 'Voir le top',
      seeAll: 'Voir tout',
      top: 'le top',
      recent: 'récemment',
      first: 'premier',
      processPaymentNow: 'Traiter le paiement maintenant',
      raised: 'levé',
      noContentAvailable: 'Aucun contenu disponible',
      topProjects: 'Meilleurs projets',
      emptyProjects: 'Aucun projet disponible',
      emptyContent: 'Aucun contenu',
      loadingContent: 'Chargement du ...',
      contacts: 'Contacts',
      support: 'Soutien',
      'terms-and-conditions': 'Règles et conditions',
      news: 'Nouvelles',
      'Failed to fetch project summary. Missing data.': 'Échec de la récupération du résumé du projet. Données manquantes.',
      'Failed to fetch project summary. Please try again later.': 'Échec de la récupération du résumé du projet. Veuillez réessayer plus tard.',
      'That is all the contributors': 'C\'est tout les contributeurs',
      'Still waiting for contributors': 'Encore en attente de contributeurs',
      'This field is required': 'Ce champ est obligatoire',
      'Please enter a valid email address': 'Veuillez saisir une adresse e-mail valide',
      'You must accept the terms and conditions': 'Vous devez accepter les termes et conditions',
      'The value must be at least {{value}}': 'La valeur doit être au moins {{value}}',
      'hideDisplayName': 'Ne pas afficher mon nom publiqu',
      'Payment could not be processed. Please try again later': 'Le paiement n\'a pas pu être traité. Veuillez réessayer plus tard.',
      'Payment Successful': 'Paiement réussi.',
      'Payment Failed': 'Paiement échoué.',
      'Payment Successful Text': 'Votre paiement a été traité avec succès! \nVeuillez vérifier l\'adresse e-mail que vous avez fournie pour votre reçu.',
      'Payment Failed Text': 'Votre paiement a été refusé. Veuillez réessayer plus tard.',
      'Provided subscription token is not valid': 'Le jeton d\'abonnement fourni n\'est pas valide.',
      'whereTheMostNeeded': 'Là où c\'est le plus nécessaire',
      'ONE-TIME': 'Une fois',
      'SUBSCRIPTION': 'Abonnement',
      'DAILY': 'Quotidien',
      'WEEKLY': 'Hebdomadaire',
      'MONTHLY': 'Mensuel',
    },
  },
  hy: {
    translation: {
      amount: 'Գումար',
      transaction: {
        succeed: 'Փոխանցումը հաջողությամբ կատարված է։',
        failed: 'Գործարքը մերժված է։',
      },
      firstName: 'Անուն',
      lastName: 'Ազգանուն',
      email: 'Էլ․ հասցե',
      notes: 'Նշումներ',
      other: 'Այլ',
      submit: 'Հաստատել',
      close: 'Փակել',
      loading: 'Բեռնվում է ․․․',
      notFound: 'Հետևյալ հղմամբ ոչինչ չի գտնվել։',
      unsubscribeSucceed: "Դուք հաջողությամբ ապաբաժանորդագրվեցիք։",
      unsubscribeFailed: "Տեղի է ունեցել սխալ։ Խնդրում ենք փորձել մի փոքր ուշ։",
      unsubscribeConfirm: "Խնդրում ենք հաստատել Ձեր ապաբաժանորդագրությունը։",
      unsubscribe: "Ապաբաժանորդագրել",
      invalidRequest: 'Սխալ հարցում։',
      read: `Կարդալ`,
      agreeTermsAndConditions: `Համաձայն եմ կանոններին և պայմաններին։`,
      allRightsReserved: `Բոլոր իրավունքները պաշտպանված են։`,
      powered: `Պատրաստված է սիրով ${COMPANY_NAME} կազմակերպության կողմից։`,
      chooseInitiative: 'Ընտրեք նախաձեռնությունը',
      select: 'Ընտրել',
      about: 'Մեր մասին',
      projects: 'Նախագծեր',
      donate: 'Նվիրաբերել',
      home: 'Գլխավոր էջ',
      contributions: 'նվիրաբերություններ',
      goal: 'նպատակ',
      highlights: 'կարևոր',
      description: 'Նկարագրություն',
      updates: 'Թարմացումներ',
      reports: 'հաշվետվություն',
      donateNow: 'Նվիրաբերեք հիմա',
      seeTop: 'Թոփ',
      seeAll: 'Բոլորը',
      top: 'Թոփ',
      recent: 'վերջինը',
      first: 'առաջինը',
      processPaymentNow: 'Վճարել հիմա',
      raised: 'հավաքված',
      noContentAvailable: 'Բովանդակություն առկա չէ։',
      topProjects: 'Թոփ նախագծեր',
      emptyProjects: 'Նախագծեր չկան',
      emptyContent: 'Բովանդակություն դատարկ է',
      loadingContent: 'Բովանդակությունը բեռնվում է ...',
      contacts: 'Հետադարձ կապ',
      support: 'Աջակցել',
      'terms-and-conditions': 'Կանոններ և պայմաններ',
      news: 'Նորություններ',
      'Failed to fetch project summary. Missing data.': 'Նախագծի ամփոփումը բեռնելու ընթացքում տեղի է ունեցել սխալ։ Բացակայող տվյալներ։',
      'Failed to fetch project summary. Please try again later.': 'Նախագծի ամփոփումը բեռնելու ընթացքում տեղի է ունեցել սխալ։ Խնդրում ենք փորձել մի փոքր ուշ։',
      'That is all the contributors': 'Աջակիցները այսքանն են',
      'Still waiting for contributors': 'Դեռևս սպասում ենք աջակիցների։',
      'This field is required': 'Այս դաշտը պարտադիր է',
      'Please enter a valid email address': 'Խնդրում ենք մուտքագրել վավեր էլ․ հասցե',
      'You must accept the terms and conditions': 'Անհրաժեշտ է ընդունել կանոնները և պայմանները',
      'The value must be at least {{value}}': 'Արժեքը պետք է լինի առավելագույնը {{value}}',
      'hideDisplayName': 'Ցույց չտալ իմ անունը փոխանցումների էջում։',
      'Payment could not be processed. Please try again later': 'Վճարումը չի կարող մշակվել։ Խնդրում ենք փորձել մի փոքր ուշ։',
      'Payment Successful': 'Վճարումը կատարվել է։',
      'Payment Failed': 'Վճարումը ձախողվել է։',
      'Payment Successful Text': 'Ձեր վճարումը հաջողությամբ կատարվել է։ Կտրոնի համար խնդրում ենք ստուգել նշված էլ․ հասցեն։',
      'Payment Failed Text': 'Ձեր վճարումը մերժվել է։ Խնդրում ենք փորձել մի փոքր ուշ։',
      'Provided subscription token is not valid': 'Տրված բաժանորդագրման կոդը անվավեր է։',
      'whereTheMostNeeded': 'Այնտեղ որտեղ անհրաժեշտ է։',
      'ONE-TIME': 'Միանվագ',
      'SUBSCRIPTION': 'Պարբերական',
      'DAILY': 'Ամենօրյա',
      'WEEKLY': 'Շաբաթական',
      'MONTHLY': 'Ամսական',
    },
  },
  en: {
    translation: {
      amount: 'Amount',
      transaction: {
        succeed: 'Transfer completed successfully.',
        failed: 'Transfer declined.',
      },
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email',
      notes: 'Notes',
      other: 'Other',
      submit: 'Submit',
      close: 'Close',
      loading: 'Loading ․․․',
      notFound: 'Requested page not found.',
      unsubscribeSucceed: "You have successfully unsubscribed.",
      unsubscribeFailed: "Something went wrong, please try later.",
      unsubscribeConfirm: "Please confirm your unsubscribe.",
      unsubscribe: "Unsubscribe",
      invalidRequest: 'Invalid request',
      read: `Read`,
      agreeTermsAndConditions: `I agree Terms and Conditions.`,
      allRightsReserved: `All rights reserved.`,
      powered: `Made with love by ${COMPANY_NAME}.`,
      chooseInitiative: 'Choose the initiative',
      select: 'Select',
      about: 'About Us',
      projects: 'Projects',
      donate: 'Donate',
      home: 'Home',
      contributions: 'contributions',
      goal: 'goal',
      highlights: 'highlights',
      description: 'Description',
      updates: 'Updates',
      reports: 'Reports',
      donateNow: 'Donate Now',
      seeTop: 'See top',
      seeAll: 'See all',
      top: 'the top',
      recent: 'recent',
      first: 'first',
      processPaymentNow: 'Process payment now',
      raised: 'raised',
      noContentAvailable: 'No content available',
      topProjects: 'Top Projects',
      emptyProjects: 'No projects available',
      emptyContent: 'No content',
      loadingContent: 'Loading content ...',
      contacts: 'Contacts',
      support: 'Support',
      'terms-and-conditions': 'Terms and Conditions',
      news: 'News',
      'Failed to fetch project summary. Missing data.': 'Failed to fetch project summary. Missing data.',
      'Failed to fetch project summary. Please try again later.': 'Failed to fetch project summary. Please try again later.',
      'noMoreTransactions': 'No more transactions',
      'That is all the contributors': 'That is all the contributors',
      'Still waiting for contributors': 'Still waiting for contributors',
      'This field is required': 'This field is required',
      'Please enter a valid email address': 'Please enter a valid email address',
      'You must accept the terms and conditions': 'You must accept the terms and conditions',
      'The value must be at least {{value}}': 'The value must be at least {{value}}',
      'hideDisplayName': 'Do not display my name publicly',
      'Payment could not be processed. Please try again later': 'Payment could not be processed. Please try again later.',
      'Payment Successful': 'Payment Successful.',
      'Payment Failed': 'Payment Failed.',
      'Payment Successful Text': 'Your payment has been processed successfully! <br/>Please check the email address you provided for your receipt.',
      'Payment Failed Text': 'Your payment has been declined. Please try again later.',
      'Provided subscription token is not valid': 'Provided subscription token is not valid.',
      'whereTheMostNeeded': 'Where the most needed',
      'ONE-TIME': 'Onetime',
      'SUBSCRIPTION': 'Subscription',
      'DAILY': 'Daily',
      'WEEKLY': 'Weekly',
      'MONTHLY': 'Monthly',
    },
  },
};

const i18nInstance = i18n
  .use(LanguageDetector)
  .use(initReactI18next);

i18nInstance.init({
  resources,
  fallbackLng: 'hy',
  supportedLngs: ['hy', 'en', 'ru', 'fr'],
}).then(() => {
  console.log('i18n initialized');
});

export default i18n;
