import axios, { AxiosResponse } from 'axios';
import { IS_DEV } from '../../helpers/utils.helper';

const headers: {[key: string]: string} = {};

if (IS_DEV) {
  headers['x-request-domain'] = 'voma'
}

export const apiBase = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/api/v3`,
  headers,
});

export class BaseService {
  protected async get<T>(url: string, params?: any): Promise<T | null> {
    try {
      const response: AxiosResponse<T> = await apiBase.get(url, { params });
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  protected async post<T>(url: string, data: any): Promise<T | null> {
    try {
      const response: AxiosResponse<T> = await apiBase.post(url, data);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
}
