import React from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { NavStyled } from '../../../Styles';
import { useCustomer } from '../../../hooks/useCustomer';
import { CustomerSubscription } from '../../../services/requests/CustomerService';

interface TransactionTypeSelectorProps {
  selectedSubscriptionTypeId: number | null;
  onSelect: (customerSubscription: CustomerSubscription) => void;
}

const SubscriptionTypeSelector: React.FC<TransactionTypeSelectorProps> = ({ selectedSubscriptionTypeId, onSelect }) => {
  const { t } = useTranslation();
  const { customer } = useCustomer();

  const handleOnClick = (customerSubscription: CustomerSubscription) => {
    onSelect(customerSubscription);
  };

  return (
    <NavStyled justified pills>
      {customer?.customerSubscriptions.map((customerSubscription) => (
        <NavItem key={customerSubscription.subscriptionTypeId}>
          <NavLink onClick={() => handleOnClick(customerSubscription)}
                   active={selectedSubscriptionTypeId === customerSubscription.subscriptionTypeId}>
            {t(customerSubscription.name)}
          </NavLink>
        </NavItem>
      ))}
    </NavStyled>
  );
};

export default SubscriptionTypeSelector;
