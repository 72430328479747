import React, { useEffect, useState } from 'react';
import Footer from '../components/Footer/Footer';
import { Outlet, useLocation } from 'react-router-dom';
import Navigation from '../components/Navigation/Navigation';
import { useCustomer } from '../hooks/useCustomer';
import Breadcrumbs from '../components/Breadcrumbs';
import { Container, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { PaymentSession, sessionService } from '../services/requests/SessionService';
import Loading from '../components/Loading';

function LayoutV2(): any {
  const { customer, loading } = useCustomer();
  const { t } = useTranslation();
  const [paymentModal, setPaymentModal] = useState(false);
  const [paymentSession, setPaymentSession] = useState<PaymentSession | null>();

  const paymentModalToggle = () => {
    setPaymentModal(!paymentModal);
  };

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const sessionId = params.get('session_id');

  useEffect(() => {

    if (!sessionId) {
      return;
    }

    sessionService
      .getPaymentSession(sessionId as string)
      .then((session) => {
        if (!session) {
          return;
        }
        setPaymentSession(session);
        paymentModalToggle();
      });

  }, []);

  if (loading) {
    return <Loading style={{ height: '100vh' }}/>;
  }

  return (
    <div className={'page-container'}>
      {!loading && customer && <>
        <header>
          <Navigation/>
        </header>
        <main className={'content-wrap'}>
          <Container>
            <Breadcrumbs/>
          </Container>
          <Outlet/>
        </main>
        <footer>
          <Footer/>
        </footer>
      </>}

      {paymentSession &&
        <Modal isOpen={paymentModal} toggle={paymentModalToggle}>
          <ModalHeader toggle={paymentModalToggle}>
            <strong>
              <b>
                {paymentSession.isSuccess ? t('Payment Successful') : t('Payment Failed')}
              </b>
            </strong></ModalHeader>
          <ModalBody>
            <div className={'mb-0'}>
              <p>
                {paymentSession.isSuccess ? t('Payment Successful Text') : t('Payment Failed Text')}
              </p>
              <hr/>
              <p className="mb-0">
                {paymentSession.state}
              </p>
            </div>
          </ModalBody>
        </Modal>
      }
    </div>
  );
}

export default LayoutV2;
;
